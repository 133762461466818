import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import './App.css';

function App() {
	const [userSocket, setSocket] = useState();
	const [roomId, setRoomId] = useState('defaultRoom'); // Или установите нужный идентификатор комнаты
	const [userId, setUserId] = useState('user2'); // Или установите уникальный идентификатор пользователя

	useEffect(() => {
		const socket = () => {
			return io(`http://uzbek-tony-stark.ru/`);
		};

		setSocket(socket());
	}, []);

	useEffect(() => {
		if (userSocket?.connected) {
			userSocket.on('connect', () => {
				console.log('Socket connected:', userSocket.id);
			});

			userSocket.on('disconnect', () => {
				console.log('Socket disconnected');
			});

			userSocket.on('connect_error', error => {
				console.error('Socket connection error:', error);
			});

			userSocket.on('user-connected', userId => {
				console.log(`User ${userId} connected`);
			});

			userSocket.on('user-disconnected', userId => {
				console.log(`User ${userId} disconnected`);
			});

			return () => {
				if (userSocket) {
					userSocket.disconnect();
				}
			};
		}
	}, [userSocket?.connected]);

	useEffect(() => {
		try {
			userSocket.emit('join', { room: roomId, name: userId });
			console.log('Выполнился');
		} catch (error) {
			console.log(error);
		}
	}, [userSocket?.connected]);

	return (
		<div className='App'>
			<div className='header'>
				<div className='logo'>
					<h2 style={{ color: '#fff' }}>Трансляция</h2>
				</div>
			</div>
			<div className='main'>
				<div className='main__left'>
					<div className='videos__group'>
						<div id='video-grid'></div>
					</div>
					{/* <div className='options'>
						<div className='options__left'>
							<div id='stopVideo' className='options__button'>
								<i className='fa fa-video-camera'></i>
							</div>
							<div id='muteButton' className='options__button'>
								<i className='fa fa-microphone'></i>
							</div>
						</div>
						<div className='options__right'>
							<div id='inviteButton' className='options__button'>
								<i className='fas fa-user-plus'></i>
							</div>
						</div>
					</div> */}
				</div>
				<div className='main__right'>
					<div className='main__chat_window'>
						<div className='messages'></div>
					</div>
					<div className='main__message_container'>
						{/* <input
							id='chat_message'
							type='text'
							autocomplete='off'
							placeholder='Type message here...'
						/>
						<div id='send' className='options__button'>
							<i className='fa fa-plus' aria-hidden='true'></i>
						</div> */}
					</div>
				</div>
			</div>
		</div>
	);
}

export default App;
